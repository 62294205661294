.container-memes {

}
/* MAKE IT NOT APPEAR.
.container-search {
    align-items: center;
}
*/
input /*, select */ {
  background-color: #282828;
  outline: none;
  color: #c4c4c4;
  font-family: unifont, sans-serif;
  font-size: 18px;
  margin: 10px;
  height: 25px;
  width: 300px;
  border: none;
  box-shadow: inset 5px 5px 0px black;
  box-sizing: content-box;
  padding: 2px 12px;
}

a {
  cursor: pointer;
  color: #282828;
  text-decoration: none;
  padding: 1px 0px 1px 3px;
}

a:hover {
  background-color: #db8885;
  color: #000000;
}

a.active, a.active {
  background-color: #db8885;
  color: #000000;
}