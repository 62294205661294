img {
  cursor: pointer;
  display: block;
  max-width:300px;
  max-height:200px;
  margin : 15px 15px 15px 15px;
  width: auto;
  height: auto;
}

.meme-container {
  margin-bottom: 5px;
  border-style: outset;
  border-color: black;
  display: block;
  width: fit-content;
};

