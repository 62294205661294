@font-face {
  font-family: unifont;
  src: url(./fonts/unifont.otf);
  font-weight: normal;
}

html, body {
  padding: 0;
  background-color: #b1e3e6;
  font-family: unifont, monospace;
}